export const environment = {
  production: true,
  buildNumber: 11,
  apiURL: 'https://api.netclass.trilhaedu.com.br/v1/mobile',
  relatnoauthURL: 'https://exemplo.trilhaedu.com.br/relatorios/prof-n/exercicio/v1-0',
  targetDevice: 'web'
};

// export const environment = {
//   production: false,
//   buildNumber: 11, 
//   // apiURL: 'http://192.168.0.186:8081/v1/mobile',
//   apiURL: 'http://localhost:8081/v1/mobile',
//   // apiURL: 'https://stg-api.netclass.trilhaedu.com.br/v1/mobile',
//   relatnoauthURL: 'https://webapp.trilhaedu.localhost/relatorios/prof-n/exercicio/v1-0',
//   // targetDevice: 'android'
//   targetDevice: 'web'
// };

// export const environment = {
//   production: false,
//   buildNumber: 10, 
//   // apiURL: 'http://192.168.0.186:8081/v1/mobile',
//   apiURL: 'http://localhost:8081/v1/mobile',
//   // apiURL: 'https://stg-api.netclass.trilhaedu.com.br/v1/mobile',
//   relatnoauthURL: 'https://webapp.trilhaedu.localhost/relatorios/prof-n/exercicio/v1-0',
//   // targetDevice: 'android'
//   targetDevice: 'web'
// };


// export const environment = {
//   production: false,
//   buildNumber: 7, 
//   // apiURL: 'http://192.168.43.11:8081/v1/mobile',
//   apiURL: 'http://localhost:8080/v1/mobile',
//   relatnoauthURL: 'https://webapp.trilhaedu.localhost/relatorios/prof-n/exercicio/v1-0',
//   targetDevice: 'web'
// };

// export const environment = {
//   production: true,
//   buildNumber: 6,
//   apiURL: 'https://api.netclass.trilhaedu.com.br/v1/mobile',
//   relatnoauthURL: 'https://exemplo.trilhaedu.com.br/relatorios/prof-n/exercicio/v1-0',
//   targetDevice: 'web'
// };

//juliana (alu), davi (prof) ,mariana (coord)