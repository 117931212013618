import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
// import { Observable } from 'rxjs';
// import { filter, map, take } from 'rxjs/operators';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
// import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FcmService {
  fcmRegistrationSuccess:any = null;
  fcmPermission:any = null;
  fcmToken:string = '';
  unreadPushMessage:any = null;

  constructor(
    private auth:AuthenticationService,
    private router:Router
  ) { }


  async initPush(){
    
    // //Se inscreve no observable de autenticação -> mensagens com app fechado
    // this.auth.isAuthenticated.pipe(
		// 	filter((val) => val !== null), // Filter out initial Behaviour subject value
		// 	take(1), // Otherwise the Observable doesn't complete!
    // ).subscribe(
    //   (isAuthenticated) => {
    //     // console.log('initPushParams-isAuthenticated',isAuthenticated);
    //     this.pushAction(this.receivedNotification);
    //     this.receivedNotification = null;
    //   }
    // );

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    
    // PushNotifications.requestPermissions().then(result => {
    //   if (result.receive === 'granted') {
    //     // Register with Apple / Google to receive push via APNS/FCM
    //     this.fcmPermission = true;
    //     this.registerListeners();
    //     PushNotifications.register();
    //   }else{
    //     alert('Usuário não permitiu notificações');
    //     this.fcmPermission = false;
    //   }
    // });

    var permissionsResult = await PushNotifications.requestPermissions();
    if (permissionsResult.receive === 'granted') {
      // Register with Apple / Google to receive push via APNS/FCM
      this.fcmPermission = true;
      this.registerListeners();
      PushNotifications.register();
    }else{
      
      //IOS!!!!!!!!!!!!!!!!
      // alert('Usuário não permitiu notificações'); // <<-------------------------------------------
      this.fcmPermission = false;

    }
  }

  registerListeners(){
    
    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        console.log('FCMTOKEN -->:',token);
        this.fcmRegistrationSuccess = true;
        this.fcmToken = token.value;
        //alert('Push registration success, token: ' + JSON.stringify(token));
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        this.fcmRegistrationSuccess = false;
        // alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        // alert('Push received: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        // alert('Push action performed: ' + JSON.stringify(notification));
        // this.pushActionWaiter(notification);
        // this.receivedNotification = notification;

        //V4:
        this.unreadPushMessage = notification;
        if (this.auth.nclToken!='' && this.auth.getPrefixRoute()!=''){
          this.pushAction(this.unreadPushMessage);
          this.unreadPushMessage = null;
        }
      }
    );
  }

  // pushActionWaiter(push: ActionPerformed){
  //   console.log('pushActionWaiter',this.auth.nclToken,this.auth.getPrefixRoute());
  //   if (this.auth.nclToken=='' || this.auth.getPrefixRoute()==''){
  //     //não está logado/sessão carregada
  //     //previne o redir pro 'home' depois do login
  //     // this.auth.preventRedirAfterAutoLogin = true;

  //     console.log('PushAction waiting session load...');
  //     setTimeout(()=>{
  //       this.pushAction(push);
  //     },1000);
  //   }else{
  //     console.log('PushAction Action!');
  //     this.pushAction(push);
  //   }
  // }

  pushAction(push: ActionPerformed){
    // console.log('notification: ',notification);
    //notificationactionId == "tap" para click na notificação
    //notification.notification.data = chaves mandadas no payload em "data"

    //problema: é possível que este evento ocorra antes da aplicação carregar as devidas informações (tokens, etc)
    //definir uma espera + gatilho para iniciar

    //se ligar também no "badge count" do app

    // console.log(push);

    //Neste formato, esta função terá como retorno o input de um canLoad Guard.
    var data:any = {};
    try{
      data = JSON.parse(push.notification.data.netclass);
      console.log('PushNotification Netclass Payload:', data);
    }catch(e){return};

    if (!(('action' in data) || ('details' in data))){
      console.log('Notificação action ou details não informados');
      return true; //sem interferência
    }

    switch(data.action){
      case 'goToExercicio':
        if (!('id' in data.details)){
          console.log('Notificação goToExercicio -> id não existente');
          return true; //sem interferência
        }
        console.log('FCM -> GoToExercicio');
        this.router.navigateByUrl(this.auth.getPrefixRoute()+'/mob-exercicio/'+String(data.details.id), {replaceUrl:true});
        return false; //com interferência na rota
        break;

      case 'goToHome':
        switch (this.auth.session.vinculo){
          case 'alu':
            this.router.navigateByUrl(this.auth.getPrefixRoute()+'/tabs/home', {replaceUrl:true});
            break;
        }
        break;

    }

    return true; //sem interferência
  }

}
