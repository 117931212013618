import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { DeviceInfosService } from './services/device-infos.service';
import { FcmService } from './services/fcm.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})


export class AppComponent implements OnInit {

  constructor(
    private authService: AuthenticationService,
    private deviceInfos: DeviceInfosService,
    private fcm: FcmService,
  ) { 
    
  }

  async ngOnInit(){
    await SplashScreen.hide();
    if (environment.targetDevice=='ios' || environment.targetDevice=='android'){
      await this.fcm.initPush();
    }
    await this.deviceInfos.load();
  }
}
