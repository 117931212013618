import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

//https://betterprogramming.pub/creating-angular-webapp-for-multiple-views-and-screen-sizes-50fe8a83c433

const routes: Routes = [
  //Uma forma de verificar a plataforma e ter dois
  //roteamentos diferentes -> frame mobile e frame web

  //Por enquanto, apenas mobile
  {
    path: 'mobile',
    loadChildren: () => import('./frames/mobile/mobile.module').then( m => m.MobileModule)
  },
  {
    path: 'external',
    loadChildren: () => import('./external/external.module').then( m => m.ExternalModule)
  },
  {
    path: '',
    redirectTo: 'mobile',
    pathMatch: 'full'
  },


  //Abaixo adicionados por engano? Conferir.

  {
    path: 'perfil',
    loadChildren: () => import('./pages/perfil/perfil.module').then( m => m.PerfilPageModule)
  },
  {
    path: 'perfil-editar',
    loadChildren: () => import('./pages/perfil-editar/perfil-editar.module').then( m => m.PerfilEditarPageModule)
  },
  {
    path: 'area-treinamento',
    loadChildren: () => import('./pages/aluno/area-treinamento/area-treinamento.module').then( m => m.AreaTreinamentoPageModule)
  },
  
  // {
  //   path: 'videoaula',
  //   loadChildren: () => import('./pages/videoaula/videoaula.module').then( m => m.VideoaulaPageModule)
  // },
  // {
  //   path: 'questao',
  //   loadChildren: () => import('./pages/questao/questao.module').then( m => m.QuestaoPageModule)
  // }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {  }
