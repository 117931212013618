import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { CacheImagesService } from './cache-images.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceInfosService {

  deviceId:string = '';
  deviceInfo:any = null;

  constructor(
    private cache: CacheImagesService
  ) { }

  async load(){
    const deviceId = await Device.getId();
    this.deviceId = deviceId.identifier;
    const deviceInfo = await Device.getInfo();
    this.deviceInfo = deviceInfo;
    await this.cache.load();
  }
}
