import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { NclApiCallService } from './ncl-api-call.service';


@Injectable({
  providedIn: 'root'
})
export class CacheImagesService{
  expirationPeriod: number = 60 * 24 * 60 * 60 * 1000; //2 meses (qtd_dias * 24 hrs * 60 min * 60 seg * 1000 mls)
  usedStorage: number = 0;
  maxStorage: number = 5 * 1024 * 1024; // 5 MB
  prefix: string = 'cache-imgs:';
  canAddInCache: boolean = true;

  constructor(
  ) { }

  async load(){
    await this.clearStoredImages();
  }

  async logout(){
    await this.clearStoredImages();
  }

  async getImage(endpoint: string, api: NclApiCallService): Promise<string> {

    const key = this.prefix + endpoint;
    const storedData = await Storage.get({ key: key });
    const currentDate = new Date().getTime();

    if (storedData.value) {
      const { expirationDate, imageData } = JSON.parse(storedData.value);
      if (currentDate < expirationDate) {
        return `data:image/png;base64,${imageData}`;
      }

      await this.removeImage(key);
    }

    return this.fetchImage(endpoint, currentDate, api);
  }

  private async fetchImage(endpoint: string, currentDate: number, api: NclApiCallService): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const res = await api.getFile(endpoint).toPromise();
        const reader = new FileReader();
  
        reader.onloadend = async () => {
          const base64data = reader.result?.toString()?.split(',')[1] || '';
          const size = this.estimateSize(base64data);
  
          if (this.usedStorage + size >= this.maxStorage) {
            this.canAddInCache = false;
          }
  
          if (this.canAddInCache) {
            const expirationDate = currentDate + this.expirationPeriod;
  
            const dataToStore = {
              expirationDate: expirationDate,
              imageData: base64data,
              size: size
            };

            const key = this.prefix + endpoint;
  
            await Storage.set({ key: key, value: JSON.stringify(dataToStore) });
            await this.calculateCapacity();
          }
          resolve(`data:image/png;base64,${base64data}`);
        };
  
        reader.readAsDataURL(res);
      } catch (error) {
        console.log('Erro ao buscar a imagem:', error);
        reject(error);
      }
    });
  }

  private async clearStoredImages(){
    const storageInfo = await Storage.keys();
    const imagesKey = storageInfo.keys.filter(key => key.startsWith(this.prefix));

    for (const key of imagesKey) {
      await Storage.remove({ key: key });
    }
    this.calculateCapacity();
  }

  private async calculateCapacity() {
    const storageInfo = await Storage.keys();
    const keysWithPrefix = storageInfo.keys.filter(key => key.startsWith(this.prefix));

    let usedStorage = 0;

    for (const key of keysWithPrefix) {
      const data = await Storage.get({ key });
      const size = JSON.parse(data.value).size;
      usedStorage += size;
    }

    this.usedStorage = usedStorage;

    if(usedStorage < this.maxStorage){
      this.canAddInCache = true;
    }
  }


  private estimateSize(data: string): number {
    return data.length * 2;
  }

  private async removeImage(key: string): Promise<void> {
    await Storage.remove({ key });
    await this.calculateCapacity();
  }

}

