import { Inject, Injectable, forwardRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, switchMap} from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { environment} from '../../environments/environment';
import { Storage } from '@capacitor/storage';
import { DeviceInfosService } from './device-infos.service';
import { CacheImagesService } from './cache-images.service';

 //Funcionamento impedido: Builds 1 a 3

//Build 1 e 2
const TOKEN_KEY = 'nclToken';
const REFRESH_TOKEN_KEY = 'nclRefreshToken';
const SESSION_KEY = 'nclSession';
//Build 3
const OPTIONS_KEY = 'nclOptions';
//Build 4
const SESSION_ID_KEY = 'nclSessionId';
const LAST_BUILD_KEY = 'nclLastBuild';
const AVATAR_KEYS = ['stored-item-shirt','stored-item-shoes','stored-item-shorts','stored-item-glasses'];

const STD_OPTIONS = {
  'tabs':{
    'prevest':false
  }
};

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService{

  //Variáveis de controle//
  // Init with null to filter out the first value in a guard!
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  nclToken = '';
  nclRefreshToken = '';
  sessionId:number = 0;
  lastBuild:number = 0;
  session = {
    id:'',
    email:'',
    nome:'',
    nomeUsual:'',
    idInst:'',
    tipoInst:'',
    nomeInst:'',
    vinculo:'',
    plataforma:''
  };

  options:any = {};

  resetAuthVars(){
    this.nclToken = '';
    this.nclRefreshToken = '';
    this.sessionId = 0;
    // this.lastBuild = 0;
    this.session = {
      id:'',
      email:'',
      nome:'',
      nomeUsual:'',
      idInst:'',
      tipoInst:'',
      nomeInst:'',
      vinculo:'',
      plataforma:''
    };
  
    //Melhorar aqui (erro no logout)
    this.options = {...STD_OPTIONS};
  }

	constructor(
    private http: HttpClient,
    private deviceInfos: DeviceInfosService,
    private cache: CacheImagesService
  ) {
		this.loadTokens();
	}

	async loadTokens() {   
    console.log('Loading Tokens...');

    const token = await Storage.get({ key: TOKEN_KEY });
    const refToken = await Storage.get({ key: REFRESH_TOKEN_KEY });
		const session = await Storage.get({ key: SESSION_KEY });
    const options = await Storage.get({ key: OPTIONS_KEY });
    const sessionId = await Storage.get({ key: SESSION_ID_KEY });
    const lastBuild = await Storage.get({ key: LAST_BUILD_KEY });
		
    //Build 4 Params
    if (sessionId && sessionId.value){
      this.sessionId = parseInt(sessionId.value);
      console.log('loaded sessionId: ',sessionId.value);
    }
    if (lastBuild && lastBuild.value){
      this.lastBuild = parseInt(lastBuild.value);
      console.log('loaded lastBuild: ',lastBuild.value);
    }

    //options (Build 3 Params)
    if (options && options.value){
      this.options = JSON.parse(options.value);
      console.log('loaded options: ',options.value);
    }else{
      this.setOptions(STD_OPTIONS);
    }

    //tokens
    if (token && token.value && refToken && refToken.value) {
			console.log('loaded token: ', token.value);
      console.log('loaded refToken: ', refToken.value);
      console.log('loaded session: ', session.value);

			this.nclToken = token.value;
      this.nclRefreshToken = refToken.value;
      this.session = JSON.parse(session.value);

			this.isAuthenticated.next(true);
		}else{
			this.isAuthenticated.next(false);
		}
	}


  // login(credentials: {email:string;password:string}): Observable<any> {
	// 	return this.http.post(environment.apiURL+'/auth', credentials)
	// }


	login(credentials: {email:string;password:string}): Observable<any> {
    var extdCredentials:any = {
      'email':credentials.email,
      'password':credentials.password,
      'buildNumber':environment.buildNumber,
      'deviceId':this.deviceInfos.deviceId
    }
    return this.http.post(environment.apiURL+'/auth', extdCredentials).pipe(
  
			switchMap((data:any) => {
        // console.log('aqui:',data.data);
        let sessionData = {
          id:<string>data.data['Id'],
          email:<string>data.data['email'],
          nome:<string>data.data['nome'],
          nomeUsual:<string>data.data['nome_usual'],
          idInst:<string>data.data['Id_inst'],
          tipoInst:<string>data.data['tipo_inst'],
          nomeInst:<string>data.data['nome_inst'],
          vinculo:<string>JSON.parse(data.data['vinculos'])[0],
          plataforma:<string>data.data['plataforma']
        }
        // console.log('session data',sessionData);
        this.sessionId = data.sessionId;
        this.session = sessionData;
        this.nclToken = data.token;
        this.nclRefreshToken = data.ref_token;
        console.log('Reg. session:',this.session);

        let promises = [
          Storage.set({ key: TOKEN_KEY, value: data.token}),
          Storage.set({ key: REFRESH_TOKEN_KEY, value: data.ref_token}),
          Storage.set({ key: SESSION_KEY, value: JSON.stringify(sessionData)}),
          this.setOptions(STD_OPTIONS),
          Storage.set({ key: SESSION_ID_KEY, value: String(this.sessionId)}),
          // Storage.set({ key: LAST_BUILD_KEY, value: String(environment.buildNumber)})
        ];
        return from(Promise.all(promises))
			}),
			tap(() => {
				this.isAuthenticated.next(true);
			})
		);
	}

  async logout(){
    //Logout request authorization header by HTTP interceptor
    var logoutBody = {
      'sessionId':this.sessionId
    }

    //limpar cache de imagens
    await this.cache.logout();

    var registerLogout = this.http.post(environment.apiURL+'/logout', logoutBody).toPromise();

    let promises = [
      Storage.remove({key: TOKEN_KEY}),
      Storage.remove({key: REFRESH_TOKEN_KEY}),
      Storage.remove({key: SESSION_KEY}),
      Storage.remove({key: OPTIONS_KEY}),
      Storage.remove({key: SESSION_ID_KEY}),
      // Storage.remove({key: LAST_BUILD_KEY}),
      registerLogout
    ];

    for(let i = 0; i < AVATAR_KEYS.length; i++){
      promises.push(Storage.remove({key: AVATAR_KEYS[i]}));
    }
    var result = await Promise.all(promises);
    console.log('Logout result: ',result);
    this.resetAuthVars();
    this.isAuthenticated.next(false);
	}

  converterVinculo(sigla): string{
    let vinculos = {
      alu:'aluno',
      prof:'professor',
      coord:'coordenador'
    }
    return vinculos[sigla];
  }

  getPrefixRoute(): string{
    if (this.session.vinculo!='' && this.session.plataforma!=''){
      return '/'+this.session.plataforma+'/'+this.converterVinculo(this.session.vinculo);
    }else{
      return '';
    }
  }

  async mudarInstituicao(data:any,token:any,ref_token:any,sessionId:any){
    console.log('Mudar Instituição INIT -> ',data,token,ref_token,sessionId);
    let sessionData = {
      id:<string>data['Id'],
      email:<string>data['email'],
      nome:<string>data['nome'],
      nomeUsual:<string>data['nome_usual'],
      idInst:<string>data['Id_inst'],
      tipoInst:<string>data['tipo_inst'],
      nomeInst:<string>data['nome_inst'],
      vinculo:<string>JSON.parse(data['vinculos'])[0],
      plataforma:<string>data['plataforma']
    }
    // console.log('session data',sessionData);
    this.sessionId = sessionId;
    this.session = sessionData;
    this.nclToken = data.token;
    this.nclRefreshToken = data.ref_token;
    console.log('New session:',this.session);

    await Storage.set({ key: SESSION_ID_KEY, value: String(this.sessionId)});
    await Storage.set({ key: TOKEN_KEY, value: token});
    await Storage.set({ key: REFRESH_TOKEN_KEY, value: ref_token});
    await Storage.set({ key: SESSION_KEY, value: JSON.stringify(sessionData)});

    await this.loadTokens();

    console.log('Mudar Instituição Finished');
  }

  async setOptions(optionsObj:any){
    console.log('setting options',optionsObj);
    this.options = {...optionsObj};
    await Storage.set({ key: OPTIONS_KEY, value: JSON.stringify(optionsObj)})
    console.log('New options registered: ',this.options);
  }

  async setOption(key:any,value:any){
    switch (key){
      case 'tabs.prevest':
        this.options.tabs.prevest = value;
        await Storage.set({ key: OPTIONS_KEY, value: JSON.stringify(this.options)})
        console.log('New option registered: ',this.options);
        break;
    }
  }

  async reformStorage(sessionId:any,lastBuild:any){
    //Reforming Storage for changes in this app version
    console.log('ReformStorage -> ',sessionId,lastBuild);

    if (parseInt(sessionId)!=0){
      this.sessionId = sessionId;
      await Storage.set({ key: SESSION_ID_KEY, value: String(sessionId)});
      console.log('New sessionId registered: ',this.sessionId);
    }

    if (parseInt(lastBuild)!=0){
      this.lastBuild = lastBuild;
      await Storage.set({ key: LAST_BUILD_KEY, value: String(lastBuild)});
      console.log('New lastBuild registered: ',this.lastBuild);
    }
  }

  async applyNewLastBuild(){
    this.lastBuild = environment.buildNumber;
    await Storage.set({ key: LAST_BUILD_KEY, value: String(environment.buildNumber)});
  }

}